import { Headline2, Paragraph2 } from '@/shared/components/Typography';
import Box from '@material-ui/core/Box';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import StatsTable from '@/shared/components/StatsTable';
import SecondaryButton from '@/shared/components/Button/SecondaryButton';
import { sectionMessages } from '../../messages';
import { IGroup } from '@/shared/models/Group';
import { Img } from '@/shared/components/Img/Img';
import { ISectionComponentProps } from 'components/Sections/utils';
import { SectionLayoutType } from '@/shared/models/Section';
import { useSystemPagePath } from 'utils/hooks';
import { WebsitePageType } from '@/shared/models/Website';
import { DEFAULT_TEAM_LOGO } from '@/shared/components/Avatar/Avatar';
import Card from '@/shared/components/Card';

interface IStandingsBaseLayoutProps extends ISectionComponentProps {
    hideHeader?: boolean
    hideViewMore?: boolean
}

export default function StandingsBaseLayout(props: IStandingsBaseLayoutProps) {
    const intl = useIntl()
    const { data, section, hideHeader, hideViewMore } = props
    const { content } = section
    const  { league, standingsStats, group: selectedGroup, season } = data
    const isEmpty = standingsStats?.length === 0
    const leagueStatsLink = useSystemPagePath(WebsitePageType.STANDINGS, { 'leagues': league.id, ...season?.id ? { 'seasons': season.id } : {}})
    const groupStatLink =  useSystemPagePath(WebsitePageType.STANDINGS, { 'leagues': league.id, ...season?.id ? { 'seasons': season?.id } : {}, ...content?.team?.id ? {'groups': content.team?.id} : {}}) 
    const standingsLink = section.layout === SectionLayoutType.TEAM ? groupStatLink : leagueStatsLink

    return (
        <Box>
            {!hideHeader && (
                <Box pb={4}>
                    <Box display='flex' alignItems='center'>
                        <Img mods='w64' src={league.picture ?? DEFAULT_TEAM_LOGO} failoverImage={DEFAULT_TEAM_LOGO}/>
                        <Box p={2}>
                            <Headline2>{league.name}</Headline2>
                        </Box>
                    </Box>
                </Box>
            )}
            {standingsStats?.map(stats => {
                    const penaltyPointsData = stats.footer || []
                    const hasPenaltyPoints = penaltyPointsData.some(p => p.points !== 0 && Number.isInteger(p.points))
                    return (
                        <>
                            <StatsTable<IGroup>
                                key={stats.phase?.id}
                                entityKey='group'
                                stats={stats}
                                highlightRow={(group) => group?.id === selectedGroup?.id}
                                getEntityNameNode={getEntityNameNode}
                                getEntityNumberNode={group => group.ranking}
                                entityNameHeaderNode={<span>{intl.formatMessage(sectionMessages['table.header.club'])}</span>}
                                getFieldKey={(key, memberId, index) => getFieldKeys(memberId, index, league.id).getStatKey(key)}
                            />
                            {hasPenaltyPoints ? (
                                <Box pb={4}>
                                    <Card>
                                        <Box p={4}>
                                            {penaltyPointsData.map(p => {
                                                if (!Number.isInteger(p.points) || p.points === 0) return null
                                                return (
                                                    <Box key={p.group.id + '-penalty'} mb={2}>
                                                        <Paragraph2>
                                                            *{p.group?.full_name || ''}: {p.points} {(p.points === 1 || p.points === -1) ? intl.formatMessage(sectionMessages['standings.point']) : intl.formatMessage(sectionMessages['standings.points'])}
                                                        </Paragraph2>
                                                        {p.note ? <Paragraph2>
                                                            {intl.formatMessage(sectionMessages['standings.comment'])}: {p.note}
                                                        </Paragraph2> : null}
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Card>
                                </Box>
                            ) : null}
                        </>
                    )
                })
            }
            {!isEmpty && !hideViewMore && (
                <Box justifyContent='center' display='flex'>
                    <SecondaryButton applyBackgroundColor href={standingsLink}>{intl.formatMessage(sectionMessages['website.view_more'])}</SecondaryButton>
                </Box>
            )}
        </Box>
    )

    function getFieldKeys(memberId: number, index: number, teamId: number) {
        return {
            getStatKey: (key: string) => `${memberId}-${teamId}-${index}-${key}`
        }
    }

    function getEntityNameNode(group): ReactNode {
        return (
            <Box display='flex' alignItems='center'>
                <Box mr={1} minWidth={25}>
                    <Img mods='w24' 
                        src={group?.pic ?? DEFAULT_TEAM_LOGO}
                        failoverImage={DEFAULT_TEAM_LOGO}
                    />
                </Box>
                <Box>
                    {group?.name}
                </Box>
            </Box>
        )
    }
}