
import Card from '@/shared/components/Card';
import OverviewTable, { IOverviewTableProps } from '@/shared/components/OverviewTable/OverviewTable';
import { Headline3, Paragraph2 } from '@/shared/components/Typography';
import { IStatisticsInfo } from '@/shared/models/Game';
import Box from '@material-ui/core/Box';
import { sectionMessages } from 'components/Sections/messages';
import { useTranslations } from 'contexts/translations';
import { useIntl } from 'react-intl';
import TablePlaceholder from 'components/Sections/Placeholders/TablePlaceholder'

type IStatsTableProps<T> = {
    stats: IStatisticsInfo<T>
    hideStageName?: boolean
} & Pick<IOverviewTableProps, 'getEntityNameNode' | 'getFieldKey' | 'getEntityNumberNode' | 'entityNameHeaderNode' | 'headerDisplay' | 'entityKey' | 'highlightRow'>
export default function StatsTable<T>(props: IStatsTableProps<T>) {
    const { stats, getEntityNameNode, getFieldKey, getEntityNumberNode,
        entityNameHeaderNode, headerDisplay, entityKey = 0, highlightRow,
        hideStageName
    } = props
    const { translations: { messages } } = useTranslations()
    const { header } = stats
    const headerLength = Object.keys(header).length
    const isEmpty = stats.rows.length === 0 || headerLength === 0
    const intl = useIntl()

    if (isEmpty && !stats?.phase) return null

    return (
        <>
            {isEmpty && (
                <>
                    <Box pb={4}>
                        {(stats?.phase?.name && !hideStageName) && <Headline3>{stats?.phase?.name}</Headline3>}
                        <Headline3>{intl.formatMessage(sectionMessages['table.empty'])}</Headline3>
                    </Box>
                    <Box pb={4}>
                        <TablePlaceholder rows={4} />
                    </Box>
                </>
            )}
            {!isEmpty && (
                <>
                    {(stats?.phase?.name && !hideStageName) && (
                        <Box pb={2} pt={4}>
                            <Headline3>{stats?.phase?.name}</Headline3>
                        </Box>
                    )}
                    <Box pb={4}>
                        <OverviewTable
                            entityKey={entityKey}
                            header={header}
                            translations={messages}
                            entityNameHeaderNode={entityNameHeaderNode}
                            rows={stats.rows}
                            mods='media-md'
                            isEditMode={false}
                            getEntityNameNode={getEntityNameNode}
                            getFieldKey={getFieldKey}
                            getEntityNumberNode={getEntityNumberNode}
                            headerDisplay={headerDisplay}
                            highlightRow={highlightRow}
                        />
                    </Box>
                    {headerDisplay !== 'name' && (
                        <Box pb={4}>
                            <Card>
                                <Box p={4}>
                                    <Paragraph2>
                                        {Object.values(header).filter(item => {
                                            return item.showable !== false
                                        }).map((item) => {
                                            return `${messages[item.acronym]} = ${messages[item.name]}`
                                        }).join(', ')}
                                    </Paragraph2>
                                </Box>
                            </Card>
                        </Box>
                    )}
                </>
            )}
        </>
    )
}