import { IPenaltyPointsGetResponse, IPlayerProfileStat, IStatisticsInfo } from '@/shared/models/Game';
import { IGroup } from '@/shared/models/Group';
import { IPaginatedResponse } from '@/shared/models/Pagination';
import { ISportStats } from '@/shared/models/Sport';
import  { IRequestQuery, request } from './api'

export async function getPlayerStatsSection({ clubPrefix, groupId, leagueId, statId, seasonId, query }: { clubPrefix: string, groupId?: number, leagueId: number, statId: number, seasonId: number, query?: IRequestQuery }): Promise<IStatisticsInfo> {
    if(groupId) {
        return request({
            endpoint: `/competitions/${leagueId}/seasons/${seasonId}/groups/${groupId}/stat/${statId}`,
            clubPrefix,
            query
        }) 
    }

    return request({
        endpoint: `/competitions/${leagueId}/seasons/${seasonId}/stat/${statId}`,
        clubPrefix,
        query
    })
}

export async function getPlayerStatsPage({ clubPrefix, groupId, leagueId, seasonId, query }: { clubPrefix: string, groupId?: number, leagueId: number, seasonId: number, query?: IRequestQuery }): Promise<IPaginatedResponse<IStatisticsInfo>> {
    if(groupId) {
        return request({
            endpoint: `/competitions/${leagueId}/seasons/${seasonId}/groups/${groupId}/stats`,
            clubPrefix,
            query
        })
    }
    return request({
        endpoint: `/competitions/${leagueId}/seasons/${seasonId}/stats`,
        clubPrefix,
        query
    })
}

export async function getLeagueStandings({ clubPrefix, leagueId, seasonId, query }: { clubPrefix: string, leagueId: number, seasonId: number, query?: IRequestQuery }): Promise<IStatisticsInfo<IGroup>[]> {
    return request({
        endpoint: `/competitions/${leagueId}/seasons/${seasonId}/standings`,
        clubPrefix,
        query
    })
}

export async function getPlayerCurrentSeasonStat({ id, clubPrefix }: { id: number, clubPrefix: string }): Promise<IPlayerProfileStat[]> {
    return request({
        endpoint: `/users/${id}/stats/current`,
        clubPrefix,
    })
}

export async function getPlayerAllStats({ id, clubPrefix }: { id: number, clubPrefix: string }): Promise<IPlayerProfileStat[]> {
    return request({
        endpoint: `/users/${id}/stats/career`,
        clubPrefix,
    })
}

export async function getSportsStatsHeaders({ sports, clubPrefix, }: { sports: number[], clubPrefix: string }): Promise<ISportStats[]> {
    return request({
        endpoint: `/sports/stats/columns`,
        clubPrefix,
        query: {
            _sports: sports
        }
    })
}
