import { IStatisticsInfo, IStatisticsTeamsInfo } from '../models/Game'
import { IGameActionType } from '../models/GameActionType'
import { IMessages } from '../models/Messages'
import { ISportStats } from '../models/Sport'
import { isEmpty, isUndefined } from './lodashFunc'

export const translateActionTypes = (actions: IGameActionType[], translations: IMessages): IGameActionType[] => {
    return actions.map(action => {
        if (!action.children || isEmpty(action.children)) {
            return {
                ...action,
                translation: translations[action.name] || action.name
            }
        }

        return {
            ...action,
            translation: translations[action.name] || action.name,
            children: action.children.map(child => {
                return {
                    ...child,
                    translation: translations[child.name] || child.name
                }
            })
        }
    })
}

export function getSimpleStasInfoArrayPlaceholders<T>(statsInfo: IStatisticsInfo<T> | IStatisticsTeamsInfo | { rows: any[]; header: {}; phase: { id: any; }; }): IMessages {
    const placeholders = {}
    if (!isEmpty(statsInfo) && !isUndefined(statsInfo) && !isUndefined(statsInfo.header)) {
        Object.values(statsInfo.header).forEach(({name, acronym}) => {
            placeholders[name] = name
            placeholders[acronym] = acronym
        })
    }

    return placeholders
}

export function getSportsStatsPlaceholders(sportStats: ISportStats[]): IMessages {
    return sportStats.reduce((acc, el) => {
        const { sport, ...rest } = el
        return {
            ...acc,
            ...Object.entries(rest)
                .reduce((acc2, [, value]) => ({...acc2, [value.acronym]: value.acronym, [value.name]: value.name }), {})
        }
    }, {})
}

export function getPositionsPlaceholders(positions: { id: number, name: string }[]) {
    return positions.reduce((acc, el) => ({...acc, [el.name]: el.name}), {})
}